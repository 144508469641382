import React from 'react'
import { Navbar, Container, Nav, NavDropdown } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useNavigation } from '../pages/auth/NavigationContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarDays, faCog, faCogs, faUsers, faUsersBetweenLines, faUsersCog } from '@fortawesome/free-solid-svg-icons'

export const NavigationBar: React.FC = () => {
  const { doctor, logout } = useNavigation()
  return (
    <Navbar expand="lg">
      <Container>
        <Navbar.Brand href="#home">Denticare</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link as={Link} to="/patients"><FontAwesomeIcon icon={faUsers} className='me-1' />Pacientes</Nav.Link>
            <Nav.Link as={Link} to="/appointments"><FontAwesomeIcon icon={faCalendarDays} className='me-1' /> Citas</Nav.Link>

            <NavDropdown title={<><FontAwesomeIcon icon={faCalendarDays} className='me-1' /> Reportes</>} id="basic-nav-dropdown">
              <NavDropdown.Item as={Link} to="/reports/billing"> Facturacion</NavDropdown.Item>
            </NavDropdown>
            {doctor?.role === 'account_manager' && <Nav.Link as={Link} to="/config"><FontAwesomeIcon icon={faCogs} className='me-1' />Configuracion</Nav.Link>}
          </Nav>
          <Nav className="ms-auto">
            <Nav.Link href="#" className='text-success'>{doctor?.name}</Nav.Link>
            <Navbar.Text onClick={() => logout()} style={{ cursor: 'pointer' }} >Cerrar Sesion</Navbar.Text>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}